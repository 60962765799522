import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import ContactForm from "../components/contactForm";
import Link from "../components/link";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";

const ContactUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: file(relativePath: { eq: "hero-img.png" }) {
				publicURL
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImg = data.heroImg.publicURL;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Prime Sparks",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Contact Us",
				item:{ 
					url:`${siteUrl}/contact-us`,
					id:`${siteUrl}/contact-us`,
			},
		}
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Contact Prime Sparks in Eastbourne"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: "Contact Prime Sparks in Eastbourne",
					description: "Contact us to discuss your electrician requirements",
					images: [
						{
							url: `${schemaImg}`,
							width: 1920,
							height: 1080,
							alt: "Prime Sparks Electrical Services",
						},
					],
				}}
			/>
			<section>
				<Container className="pb-md-8 pb-5">
					<section id="contactform">
						<Row className="justify-content-center">
							<Col md={8} className="text-center pt-5">
								<h3>Get In Touch</h3>
								<hr className="  bg-primary mb-5" />
								<ContactForm />
							</Col>
						</Row>
					</section>
					<Row className="pt-8">
						<Col md={6} className="text-center pb-5 pb-md-0">
							<h3>Contact Details</h3>
							<hr className="  bg-primary mb-5" />
							<Link
								href="mailto:info@primesparks.co.uk"
								className="text-decoration-none montserrat-regular d-block pb-3"
							>
								info@primesparks.co.uk
							</Link>
							<Link
								href="tel:+447549 528 456"
								className="text-decoration-none  montserrat-regular"
							>
								07549 528 456
							</Link>
						</Col>
						<Col md={6} className="text-center">
							<h3>Our Address</h3>
							<hr className="  bg-primary mb-5" />
							<p className="py-0">22 Frobisher Close </p>
							<p className="py-0">Eastbourne </p>
							<p className="py-0">BN23 6BT</p>
						</Col>
					</Row>
					<Row>
						<Col className="text-center pt-4 ">
							<Link
								style={icon}
								target="_blank"
								className="social-icon px-2  "
								href="https://www.facebook.com/primesparkselec"
							>
								<FaFacebookSquare />
							</Link>

							<Link
								style={icon}
								target="_blank"
								className="social-icon px-2 "
								href="https://www.linkedin.com/in/roger-prime-b17023188"
							>
								<FaLinkedin />
							</Link>
						</Col>
					</Row>
				</Container>
				<Row style={{ height: "80vh" }} className="mt-4">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2522.4136060810433!2d0.31572131574358187!3d50.78644297952304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df73a11eb9c39d%3A0x3e3f24df50413f6e!2sPrime%20Sparks!5e0!3m2!1sen!2suk!4v1630494181622!5m2!1sen!2suk"
						frameBorder="0"
						aria-hidden="false"
						title="google-map"
						width="100%"
					></iframe>
				</Row>
			</section>
		</Layout>
	);
};

const icon = {
	fontSize: "2.5rem",
};

export default ContactUsPage;
