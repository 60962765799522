import React from "react";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import Footer from "./footer";
import Navigation from "./navigation";
import Messaging from "./messaging";

const Layout = ({ children }) => {
	return (
		<>
			<Messaging />
			<Navigation />
			<CookieBanner disableUrl />
			{children}
			<Footer />
		</>
	);
};

export default Layout;
