import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ContactForm() {
	const [state, handleSubmit] = useForm("xjvjjdoa");
	if (state.succeeded) {
		return (window.location = "/thank-you");
	}
	return (
		<>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
			</Helmet>
			<Form className="w-100 text-right pb-md-5" onSubmit={handleSubmit}>
				<Form.Group controlId="name">
					<Form.Control name="name" placeholder="Name" type="text" />
				</Form.Group>
				<ValidationError prefix="Name" field="name" errors={state.errors} />
				<Form.Group controlId="email" className="py-2">
					<Form.Control
						name="_replyto"
						type="email"
						placeholder="Email Address"
					/>
				</Form.Group>
				<ValidationError prefix="Email" field="email" errors={state.errors} />
				<Form.Group controlId="telephone" className="py-2">
					<Form.Control
						type="tel"
						name="telephone"
						placeholder="Telephone Number"
					/>
				</Form.Group>

				<ValidationError
					prefix="Telephone"
					field="telephone"
					errors={state.errors}
				/>
				<Form.Group controlId="message" className="pt-2 pb-0">
					<Form.Control
						name="message"
						placeholder="How can we help?"
						as="textarea"
						rows={3}
					/>
				</Form.Group>

				<ValidationError
					prefix="Message"
					field="message"
					errors={state.errors}
				/>

				{/* <Row>
					<Col>
						<div
							className="g-recaptcha ml-auto float-right pb-2"
							data-sitekey="6LeueHkcAAAAANckPYFD7yVOG3oyQ7I8jmIDUWB9"
						></div>
					</Col>
				</Row> */}
				<Row>
					<Col>
						<Button
							className="btn-form btn-primary mt-1 text-white "
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							Submit
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	);
}
export default ContactForm;
